import * as React from "react";

import { Box } from "@components/box";
import { navigate } from "gatsby";
import { Button, TextInput, ValidationMessage } from "@brainfinance/icash-component-library";
import { Subheading } from "@components/typography";
import { ForgotEmailProps } from "./types";
import { postForgotPassword, setLocalStorageUserSession } from "@helpers/auth";

export const ForgotEmailStep = (props: ForgotEmailProps) => {
  const [email, setEmail] = React.useState<string>();
  const [errorMessage, setErrorMessage] = React.useState<string>();
  const [validMessage, setValidMessage] = React.useState<string>();

  const handleEmailChange = (email?: string | number) => {
    if (email) setEmail(email.toString());
    else setEmail(undefined);
  };

  const handleSubmitEmail = () => {
    if (email)
      postForgotPassword(email)
        .then((res) => res.json())
        .then((data) => {
          if (data.error) throw new Error(data.error.message);
          if (!data.result?.message) throw new Error("An error occurred. Please contact the support team.");
          setValidMessage(data.result.message);
          setLocalStorageUserSession(data.result.session.token);
          props.onSubmit(email);
        })
        .catch((e) => setErrorMessage(e));
  };

  return (
    <Box display="flex" className="flex-col">
      <Box className="mb-[3.25rem] md:mb-[33px]">
        <Subheading>Forgot password</Subheading>
      </Box>
      {errorMessage || validMessage ? (
        <ValidationMessage className="mb-[33px]" status={errorMessage ? "error" : undefined}>
          {errorMessage || validMessage}
        </ValidationMessage>
      ) : null}
      <Box>
        <p className="global--caption">
          Please provide the email associated with your iCash account to receive instructions for completing the
          password reset process.
        </p>
        <Box display="flex" className="flex-col space-y-1.5 mb-[2rem]">
          <TextInput label="Email" onChange={handleEmailChange} />
        </Box>
        <Box display="flex" className="justify-end gap-[22px]">
          <Button
            onClick={() => {
              navigate("/login");
            }}
          >
            Return
          </Button>
          <Button disabled={!email} appearance="primary" onClick={handleSubmitEmail}>
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
