import * as React from "react";
import { SEO } from "@components/page-layout";
import { ForgotPassword } from "@containers/forgot-password";
import { Box } from "@components/box";

const ForgotPasswordPage = () => (
  <>
    <SEO title="Forgot password" bodyClass="signup" />
    <Box className="flex justify-center items-center px-[22px] sm:px-[11px] xs:p-0">
      <ForgotPassword />
    </Box>
  </>
);

export default ForgotPasswordPage;
