import * as React from "react";
import { Box } from "@components/box";
import { Subheading } from "@components/typography";
import { SignUpContainer, SignUpLeft, SignUpRight } from "@components/sign-up";
// Images
import instantLoans from "@images/products/ic-instant-loans-xl.svg";
import interac from "@images/interac.svg";
import { ForgotEmailStep } from "./forgot-email-step";
import { EmailSentStep } from "./email-sent-step";

export const ForgotPassword = () => {
  const [email, setEmail] = React.useState<string>();

  const getStep = (email?: string) => {
    if (!email) return <ForgotEmailStep onSubmit={setEmail} />;
    if (email) return <EmailSentStep emailValue={email} />;
    return "Done";
  };

  return (
    <SignUpContainer>
      <SignUpLeft className="max-w-[475px] md:max-w-none md:w-1/2">{getStep(email)}</SignUpLeft>
      <SignUpRight className="w-[845px] lg:w-[590px] md:w-1/2">
        <>
          <img
            src={instantLoans}
            className="absolute h-[527px] right-0 t-[46px] z-0 lg:h-[368px] md:h-[260px]"
            alt="Product image"
          />
          <Box className="max-w-[330px] mt-[34%] z-[1] lg:max-w-[240px] lg:mt-[50%] md:mt-[70%]">
            <Subheading className="mb-[44px] md:text-[26px] md:leading-[33px]">
              Instant loans, 24 hours a day
            </Subheading>
            <img src={interac} alt="Interac logo" className="w-[60px]" />
            <p className="global--small !text-[12px] text-interface-300 my-[22px]">
              With iCash instant loans, you can borrow up to $1500, 24/7. Receive your funds via e-Transfer almost
              instantaneously after signing your contract.
            </p>
          </Box>
        </>
      </SignUpRight>
    </SignUpContainer>
  );
};
