import * as React from "react";

import { Box } from "@components/box";
import { Subheading } from "@components/typography";
import { GatsbyLink } from "@components/gatsby-link";
import { EmailSentProps } from "./types";

export const EmailSentStep = (props: EmailSentProps) => (
  <Box display="flex" className="flex-col">
    <Box className="mb-[3.25rem] md:mb-[33px] text-center">
      <Subheading>An email is on the way</Subheading>
    </Box>
    <Box>
      <Box className="border-[1px] rounded text-center p-5 mb-[33px]">Email icone</Box>
      <p className="global--caption">If {props.emailValue} is assiociated with an iCash account, you will receive an email with instructions to reset your password.</p>
      <p className="global--input-error !relative z-0">Didn't receive an email from us? Please check your spam filter or junk mail folder first.<br/>
      Not there? <GatsbyLink to={""} inheritStyle className="underline" onClick={() => { props.emailValue }}>Re-send email</GatsbyLink></p>
    </Box>
  </Box>
);
